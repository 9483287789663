<template>
  <div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// @ is an alias to /src

export default {
  name: 'CurrentSession',
  props: ['isSinglePageEvent'],
  computed: {
    ...mapGetters('events', [
      'eventInitialSessionId',
    ]),
  },
  watch: {
    eventInitialSessionId: {
      handler: function (newSessionId) {
        if (newSessionId) {
          this.checkCurrentSessionId()
        }
      }, immediate: true
    }
  },
  methods: {
    ...mapActions('sessions', [
      'getCurrentSessionId',
    ]),
    checkCurrentSessionId () {
      this.getCurrentSessionId(this.eventInitialSessionId).then((currentSessionId) => {
        if (currentSessionId) {
          // 다음 세션 있을 때
          this.$router.replace({name: 'Session', query: {session_id: currentSessionId}}).catch(() => {})
        } else {
          // 다음 세션 없을 때
          if (this.isSinglePageEvent) {
            // 한페이지만 있을 때
            this.$router.replace({name: 'Session', query: {session_id: this.eventInitialSessionId}}).catch(() => {})
          } else {
            // 다른페이지도 있을 때
            this.$alert('현재 진행중인 세션이 없습니다. 시작 시간을 확인해주세요.', {
              type: 'info',
              confirmButtonText: '프로그램 보기'
            }).then(() => {
              this.$router.replace({name: 'Programs'}).catch(() => {})
            }) 
          }
        }
      })
    }
  },
}
</script>
